import { template as template_520b9f5139764cb5bfa9f1b92aca9e27 } from "@ember/template-compiler";
const FKLabel = template_520b9f5139764cb5bfa9f1b92aca9e27(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
