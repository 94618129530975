import { template as template_426d39f9f5c748cc86b0a351eb5eede1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_426d39f9f5c748cc86b0a351eb5eede1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
