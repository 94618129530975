import { template as template_ffa74a7de28d4a26a16302eb74c606fe } from "@ember/template-compiler";
const SidebarSectionMessage = template_ffa74a7de28d4a26a16302eb74c606fe(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
