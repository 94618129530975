import { template as template_0c175ac5ebd846958217d70bf5fe57d0 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0c175ac5ebd846958217d70bf5fe57d0(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
