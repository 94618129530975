import { template as template_a94027ecd6914df7adfa0b2b3e2c3926 } from "@ember/template-compiler";
const EmptyState = template_a94027ecd6914df7adfa0b2b3e2c3926(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
